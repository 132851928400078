import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import * as React from 'react';
import { SlidesProps } from '../SlideShowContainer.types';
import { TestIds } from '../constants';
import styles from './style/SlideShowContainer.scss';

const transitionClasses = {
  NoTransition: '',
  SlideVertical: styles.slideVertical,
  SlideHorizontal: styles.slideHorizontal,
  CrossFade: styles.crossFade,
  OutIn: styles.outIn,
} as const;

const Slides: React.FC<SlidesProps> = ({
  isPlaying,
  isSlideShowInViewport,
  reverse,
  transition,
  transitionDuration,
  currentSlideIndex,
  onSlideEntered,
  onSlideExited,
  children,
}) => {
  const ariaLive =
    !isSlideShowInViewport || (isPlaying && isSlideShowInViewport)
      ? 'off'
      : 'polite';
  return transition === 'NoTransition' ? (
    <div
      data-testid={TestIds.slidesWrapper}
      className={styles.slides}
      aria-live={ariaLive}
    >
      {children}
    </div>
  ) : (
    <TransitionGroup
      data-testid={TestIds.slidesWrapper}
      aria-live={ariaLive}
      className={classNames(
        styles.slides,
        styles.transition,
        transitionClasses[transition],
        {
          [styles.reverse]: reverse,
        },
      )}
    >
      <CSSTransition
        key={currentSlideIndex}
        timeout={transitionDuration}
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          exit: styles.exit,
          exitActive: styles.exitActive,
        }}
        onEntered={onSlideEntered}
        onExited={onSlideExited}
        unmountOnExit
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
};

export default Slides;
